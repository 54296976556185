import React, { useEffect, useState, lazy, Suspense } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import theme from "./theme";
import Header from "./components/Header";
import LoadingSpinner from "./components/LoadingSpinner";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

// Lazy load components
const Hero = lazy(() => import("./components/Hero"));
const Services = lazy(() => import("./components/Services"));
const Benefits = lazy(() => import("./components/Benefits"));
const HowItWorks = lazy(() => import("./components/HowItWorks"));
const Testimonial = lazy(() => import("./components/Testimonial"));
const About = lazy(() => import("./components/About"));
const CTASection = lazy(() => import("./components/CTASection"));
const Footer = lazy(() => import("./components/Footer"));

library.add(faChevronUp);

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    background-color: #ffffff;
    color: #333333;
    transition: all 0.3s ease-in-out;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
  }

  ${(props) => props.theme.customBullets}

  @media (max-width: 1024px) {
    html {
      font-size: 95%;
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 80%;
    }
  }
`;

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });

    // Simulate async operation
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <Suspense fallback={<LoadingSpinner />}>
        <Hero />
        <Services />
        <Benefits />
        <HowItWorks />
        <Testimonial />
        <About />
        <CTASection />
        <Footer />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
