import { css } from "styled-components";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

export const useTheme = () => useContext(ThemeContext);

export const theme = {
  colors: {
    background: "#ffffff",
    primary: "#1a237e",
    accent: "#0288d1", // Changed for better contrast
    text: "#333333",
    textSecondary: "#555555", // Changed for better contrast
    card: "#f5f5f5",
    button: "#0288d1", // Changed for better contrast
    buttonHover: "#01579b", // Changed for better contrast
    alternateBackground: "#f5f5f5",
    bullet: "#00bcd4", // New color for custom bullet points
  },
  spacing: {
    xsmall: "0.25rem",
    small: "0.5rem",
    medium: "1rem",
    large: "2rem",
    section: "4rem",
  },
  breakpoints: {
    tablet: "1024px",
    mobile: "768px",
  },
  typography: {
    h1: `
      font-size: 3.5rem;
      font-weight: 700;
      color: #1a237e;
    `,
    h2: `
      font-size: 2.5rem;
      font-weight: 700;
      color: #1a237e;
    `,
    h3: `
      font-size: 2rem;
      font-weight: 600;
      color: #1a237e;
    `,
    h4: `
      font-size: 1.5rem;
      font-weight: 600;
      color: #1a237e;
    `,
    body: `
      font-size: 1rem;
      line-height: 1.6;
      color: #333333;
    `,
    bodySecondary: `
      font-size: 0.9rem;
      line-height: 1.6;
      color: #666666;
    `,
  },
  borderRadius: "10px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease",
  container: `
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  `,
  button: {
    primary: `
      background-color: #00bcd4;
      color: #ffffff;
      padding: 0.75rem 1.5rem;
      border-radius: 30px;
      font-size: 1rem;
      font-weight: bold;
      text-decoration: none;
      transition: all 0.3s ease;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      &:hover {
        background-color: #0097a7;
        color: #ffffff;
        transform: scale(1.05) translateY(-3px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
      }
    `,
  },
  btn: css`
    display: inline-block;
    background-color: #188fa7;
    color: #efbdeb;
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
    border: 2px solid #188fa7;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    box-shadow: 0 4px 6px rgba(24, 143, 167, 0.2);

    &:hover {
      background-color: #610f7f;
      color: #efbdeb;
      border-color: #610f7f;
      box-shadow: 0 6px 8px rgba(97, 15, 127, 0.3);
      transform: scale(1.05) translateY(-2px);
    }
  `,
  btnOutline: css`
    background-color: transparent;
    color: #188fa7;
    border-color: #188fa7;

    &:hover {
      background-color: #188fa7;
      color: #efbdeb;
    }
  `,
  // Custom bullet points
  customBullets: css`
    ul {
      list-style: none;
      padding-left: 1.5em;
    }

    ul li {
      position: relative;
      margin-bottom: 0.5em;
    }

    ul li::before {
      content: "•";
      color: ${(props) => props.theme.colors.bullet};
      font-size: 1.2em;
      position: absolute;
      left: -1em;
      top: -0.1em;
    }
  `,
};

export default theme;
